import React from 'react';
import { ApolloProvider, Query } from 'react-apollo';
import client from '../lib/apolloClient';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import UserProfile from '../components/UserProfile/UserProfile';
import { isEmpty } from 'lodash';
import { IntlProvider } from 'react-intl';
import defaultLocale from '../lib/i18n/default.json';
import translations from '../lib/i18n/translations.json';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import themes from '../lib/themes';
import { get } from 'lodash';

export const UserProfileQueries = gql`
  query UserProfileQueries($userName: String) {
    currentUserInfoAuth
    companyHasFeature(featureName: "rolodex")
    companyHasCounty(featureName: "show_county")
    companyHasBilling
    companyHasAds
    companyHasNewMessages
    companyHasNetworking
    followingUser(userName: $userName)
    currentAuctionCount
    currentUserASubscriber
    countryForCompany
    companyBusinessType
    company {
      enabled_student_profiles
      student_word
      student_program_title
      partial_sale_name
    }
    getUser(userName: $userName) {
      id
      user_name
      fb_user_name
      annual_revenue
      assets_under_management
      avg_client_age
      bio
      business_continuity_state
      city
      county
      show_city
      number_of_clients
      profile_picture
      province
      recurring_revenue
      created_at
      headline
      languages
      my_rating
      nick_name
      obfuscated
      is_pure_buyer
      is_admin
      designations
      tag_list
      primary_markets
      transition_goals
      in_auction
      years_practicing
      number_of_employees
      percent_revenue_recurring
      percent_non_recurring_revenue
      percent_fee_based
      gross_production
      percent_commission
      profile_type
      mix_insurance
      mix_mutual_funds
      mix_annuities
      mix_other
      note_count
      expressed_interest
      ask_me_about
      specialized_businesses
      discretionary_investment_models
      planning_preference
      why_values
      recognition_level
      motivation
      defined_investment_style
      defined_service_model
      is_in_deal_past_out_for_receiving_signature_state
      can_view_real_name
      is_student
      amp_trainee_status
      student_url_video
      student_url_resume
      student_url_linkedin
      student_program_year
      desired_location
      college_graduation_year
      college_degree
      college_program
      college_name
      planned_certifications
      family_member_fas
      student_age
      allowed_name
      can_view_partial_sales
      affiliations  {
        id
        name
        __typename
      }
      conversation_with_me {
        id
        __typename
      }
      product_mix {
        id
        life_ifp
        home_ifp
        auto_ifp
        commercial_ifp
        health_ifp
        group_ifp
        mutual_funds_aum
        securities_aum
        annuities_aua
        segregated_funds_aua
        total_assets
        mcpi_ifp
        private_products_ifp
        crop_hail_ifp
        crop_hail_with_wind_ifp
        farm_insurance_ifp
        price_products_ifp
        replant_supplement_ifp
        gic_fixed_income
        insurance
        equity
      }
      open_partial_sale_listings {
        id
        number_of_clients
        assets_under_management
        fee_based_revenue
        trails_revenue
        direct_revenue
        commission_revenue
        segment_details
        asking_price
        friendly_name
        description
        sale_status
        questions
        answers
        reason_for_close
        reason_for_close_type
        current_user_is_an_interest
        track_view
        states
        __typename
      }
      company {
        id
        skip_product_mix_on_signup
        hide_product_mix_on_profile
        business_type
        stylesheet_name
        use_canadian_retail_wealth_product_mix
        use_p_and_c_product_mix
        allow_blocking_in_messages
        allow_user_affiliations
        enabled_student_profiles
        student_word
        student_program_title
      }
      __typename
    }
    getCurrentUser {
      id
      user_name
      name
      locale
      roles
      has_selling_transition_goals
      limited_access
      profile_type
      motivation
      approval_state
      can_match
      is_student
      amp_trainee_status
      is_fb_subscriber
      has_deal
      omit
      planning_preference
      subscribed_to_marketplace
      affiliations  {
        id
        name
        __typename
      }
      inventory_user {
        id
        marketplace_status
        __typename
      }
      company {
        id
        name
        branded_name
        seller_only_label
        business_type
        stylesheet_name
        enabled_rolodex
        ratings
        deal_room_branded_name
        allow_blocking_in_messages
        student_word
        student_program_title
        allow_user_affiliations
        __typename
        feature_types
      }
      __typename
    }
    getSpotlights(spotlights: ["nick_name", "notebook"])
    availableProducts
    availableStrengths
    managedMarketplaceOnly
    agentWordForCompany
  }
`;

const UserProfileContainer = (props, railsContext) => {
  const userName = props.userName;
  let theme = themes[railsContext.theme_name];
  window.locale = railsContext.i18nLocale || 'en';
  return (
    <IntlProvider messages={translations[window.locale]} locale="en">
      <MuiThemeProvider theme={createMuiTheme(theme)}>
        <CssBaseline />
        <ApolloProvider client={client}>
          <Query query={UserProfileQueries} variables={{ userName }}>
            {({ data, loading, refetch }) => {
              if (loading) return <div />;
              if (data.getUser) {
                return <UserProfile data={{
                  ...data, 
                  enabledStudentProfiles:  get(data, 'company.enabled_student_profiles'),
                  studentWord: get(data, 'company.student_word'),
                  studentProgramTitle: get(data, 'company.student_program_title')
                }} />;
              }
            }}
          </Query>
        </ApolloProvider>
      </MuiThemeProvider>
    </IntlProvider>
  );
};

export default UserProfileContainer;
